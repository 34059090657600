<template>
  <div class="course">
    <div class="content">
      <ModTit
        :title="module.langNamesObj[langNamesEnum[languageActive]]"
        :moreText="$t('XB_More')"
        more="/course"
        v-if="module.configuration.titleSetting.isShow"
      />
      <div
        class="card"
        :class="{
          four: module.dataCountRow === 4,
          two: module.dataCountRow === 2,
        }"
      >
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/course/detail?id=${item.courseId}&ddtab=true`"
            target="_blank"
          >
            <div class="label">
              <span v-if="item.labelId == 3"
                >{{ $t("Home.Newly")
                }}<!-- 新上架 --></span
              >
              <div class="img" v-else>
                <img
                  v-if="item.labelId == 1"
                  :src="require('@/assets/image/subject_new.png')"
                  alt="new"
                />
                <img
                  v-if="item.labelId == 2"
                  :src="require('@/assets/image/subject_hot.png')"
                  alt="hot"
                />
              </div>
            </div>
            <div
              class="cover"
              :style="{ backgroundImage: 'url(' + item.coverImg + ')' }"
            ></div>
            <div class="info">
              <div class="title">{{ item.courseName }}</div>
              <div class="bottom">
                <span v-if="item.lecturers?.length">
                  <OpenData type="userName" :openid="item.lecturers[0].name" />
                  <template v-if="item.lecturers.length > 1">...</template>
                </span>
                <span v-else
                  >{{ $t("Home.NoLecturer")
                  }}<!-- 暂无讲师 --></span
                >
                <span>{{ $t("LB_NumPersonLearning", [item.learnCount]) }}</span>
              </div>
            </div>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpenData from "@/components/OpenData.vue";
import ModTit from "./ModTit.vue";
export default {
  name: "Course",
  components: {
    OpenData,
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.course {
  .content {
    max-width: 1200px;
    margin: 0 auto;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        position: relative;
        transition: transform 0.3s ease;
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .info {
            .title {
              color: @color-theme;
            }
          }
        }
        .label {
          position: absolute;
          left: 8px;
          top: 8px;
          z-index: 1;
          .img {
            .mixinImgWrap(28px; 28px);
          }
          span {
            height: 28px;
            padding: 0 8px;
            border-radius: 28px;
            background-color: #fff;
            color: #22cf33;
            font-size: 14px;
            line-height: 28px;
          }
        }
        .info {
          .title {
            color: #333;
            font-size: 14px;
            font-weight: 700;
            .mixinEllipsis(40px, 2);
            line-height: 20px;
          }
          .bottom {
            color: #999;
            font-size: 14px;
            line-height: 20px;
            .mixinFlex(space-between; center);
          }
        }
      }
      &.two {
        .item {
          width: calc(50% - 10px);
          .mixinFlex(space-between; center);
          margin-right: 20px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .cover {
            background-size: 100% 100%;
            background-position: center;
            width: 256px;
            height: 148px;
          }
          .info {
            width: calc(100% - 256px);
            padding: 20px;
            .bottom {
              margin-top: 48px;
            }
          }
        }
      }
      &.four {
        .item {
          width: calc((100% - 20px * (4 - 1)) / 4);
          margin-right: 20px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .cover {
            background-size: 100% 100%;
            background-position: center;
            width: 100%;
            height: 148px;
          }
          .info {
            padding: 16px;
            .bottom {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
.portal-col-70 {
  .course {
    .content {
      .card {
        &.two {
          .item {
            width: calc((100% - 20px * (2 - 1)) / 2);
            margin-right: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .cover {
              width: 147px;
              height: 85px;
            }
            .info {
              width: calc(100% - 147px);
              padding: 10px;
              .bottom {
                margin-top: 5px;
              }
            }
          }
        }
        &.four {
          .item {
            width: calc((100% - 20px * (3 - 1)) / 3);
            margin-right: 20px;
            &:nth-child(3n) {
              margin-right: 0;
            }
            .cover {
              width: 100%;
              height: 142px;
            }
            .info {
              padding: 14px;
              .bottom {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.portal-col-50 {
  .course {
    .content {
      .card {
        &.two {
          .item {
            width: 100%;
            margin-right: 0;
          }
        }
        &.four {
          .item {
            width: calc((100% - 20px * (2 - 1)) / 2);
            margin-right: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .cover {
              width: 100%;
              height: 148px;
            }
            .info {
              padding: 16px;
              .bottom {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}
.portal-col-30,
.portal-col-33,
.portal-col-35 {
  .course {
    .content {
      .card {
        &.two {
          .item {
            width: 100%;
            margin-right: 0;
            .cover {
              width: 147px;
              height: 85px;
            }
            .info {
              width: calc(100% - 147px);
              padding: 10px;
              .bottom {
                margin-top: 5px;
              }
            }
          }
        }
        &.four {
          .item {
            width: calc((100% - 10px * (2 - 1)) / 2);
            margin-right: 10px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .cover {
              width: 100%;
              height: 98px;
            }
            .info {
              padding: 10px;
              .bottom {
                font-size: 12px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
